import React from 'react'
import Gallery from 'react-grid-gallery'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import styles from '../constants/theme'
import virtualSymposiumImage from '../images/virtual-symposium-image.png'
import virtualSymposiumLinkPdf from '../static/VirtualSymposiumLinksOctober2020.pdf'

import video1 from '../static/videos/SevenReasonsWhyYouShouldDitchBEDMAS.mp4'
import video2 from '../static/videos/Learning Business Statistics.mp4'
import ppt2 from '../static/ppts/OCMA Presentation Slides - Stats for Business.pdf'
import video3 from '../static/videos/OERs and the Vision of Mathematics Education in the Open.mp4'
import ppt3 from '../static/ppts/OERs and the Vision of Mathematics Education in the Open.pdf'
import ppt4 from '../static/ppts/OCMA_2020_Teaching Math with Pear Deck_Elisa Romeo.pdf'
import video5 from '../static/videos/Decolonizing and Indigenizing Math Learning Spaces.mp4'
import video6 from '../static/videos/The Health Numeracy Project.mp4'
import video7 from '../static/videos/The Art and Science of Teaching Online Statistics.mp4'
import ppt7 from '../static/ppts/The Art and Science of Teaching Online Statistics-OCMA2020.pdf'
import video8 from '../static/videos/Teaching Vocational Mathematics.mp4'
import video10 from '../static/videos/Inside and Outside the Classroom with Business Mathematics.mp4'
import ppt10 from '../static/ppts/Math Finance Presentation - OCMA Virtual Symposium Oct 2020.pdf'
import video11 from '../static/videos/Number Systems and Alternative Notations for use in Theoretical and Applied Contexts.mp4'
import video12 from '../static/videos/Raising the Level of Numeracy.mp4'
import ppt12 from '../static/ppts/OCMA Presentation EB_JH.pptx.pdf'
import video13 from '../static/videos/Innovation in Mathematics and Statistics for Health Sciences.mp4'
import ppt13 from '../static/ppts/OCMA Presentation Slides - MHS and SHS.pdf'
import video14 from '../static/videos/The Mathematical Write Stuff.mp4'
import ppt14 from '../static/ppts/MathematicalWriteStuff.pdf'

import SEO from '../components/seo'
import Link from '../components/Link'
import Layout from '../components/layout'
import Text from '../components/Text'
import StyledLink from '../components/StyledLink'
import Hero from '../components/Hero'
import MainContentWrap from '../components/MainContentWrap'
import ContentSection from '../components/ContentSection'
import CustomContainer from '../components/CustomContainer'
import AnchorTarget from '../components/AnchorTarget'
import { useSpring } from 'react-spring'

export const pageQuery = graphql`
  query ($id: String!, $year: String) {
    confHeroImage: file(relativePath: { eq: "virtual-symposium-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    confPage: wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        title
        thematic_title
        date
        banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920, toFormat: JPG) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
        feedback_link {
          title
          url
        }
        contact_text
        conference_day {
          day
          time_slot {
            time_column_text
            presentation {
              description
              location
              background
              button {
                acf_fc_layout
                file {
                  link
                }
                link {
                  title
                  url
                }
                button_text
              }
              logo_fields {
                logo_image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 96, quality: 80) {
                        src
                      }
                    }
                  }
                }
                logo_link {
                  url
                }
              }
            }
          }
        }
        sponsors_heading
        sponsors {
          link
          logo {
            localFile {
              childImageSharp {
                resize(height: 120, quality: 80) {
                  src
                }
              }
            }
          }
          thank_you_note
        }
      }
    }
    galleryPage: wordpressPage(title: { eq: $year }) {
      title
      acf {
        gallery {
          localFile {
            childImageSharp {
              thumb: resize(width: 276, quality: 80) {
                src
                width
                height
              }
              big: resize(width: 800, quality: 80) {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default function ConferencePage({ data }) {
  const [, setYScroll] = useSpring(() => ({ y: 0 }))

  const conferenceAcf = data.confPage.acf

  const galleryAcf = data.galleryPage && data.galleryPage.acf
  let gallery = null

  if (galleryAcf && galleryAcf.gallery) {
    let usableGallery = galleryAcf.gallery.filter(
      (image) => image.localFile !== null,
    )
    gallery = usableGallery.map((image) => ({
      src: image.localFile.childImageSharp.big.src,
      thumbnail: image.localFile.childImageSharp.thumb.src,
      thumbnailWidth: image.localFile.childImageSharp.thumb.width,
      thumbnailHeight: image.localFile.childImageSharp.thumb.height,
    }))
  }

  const galleryRef = React.useRef(null)

  const virtualSymposiumContent = `
    <p><img class="alignnone size-full wp-image-1542" src="${virtualSymposiumImage}" alt="" /></p>
    <div style="padding: 24px; background: #2370ee; color: white; border-radius: 8px; margin-bottom: 24px;">

    <p style="text-align: left; font-weight: bold; font-size: 24px">The OCMA Virtual Symposium</p>
    <p style="text-align: left;">Digitally sync with your colleagues from colleges across Ontario to:</p>
    <ul style="text-align: left;">
      <li>discover ways to help build algebra and numeracy skills</li>
      <li>incorporate OERs and new learning frameworks in online environments</li>
      <li>expand your math teaching footprint in new vocational and cultural directions</li>
      <li>share successes and challenges in teaching math during a global pandemic</li>
    </ul>
    <p style="text-align: left;">Date: October 27 - 29, 2020</p>
    <p style="text-align: left;">Location: Your office or your couch</p>
    <p style="text-align: left;">Cost: None</p>
    <p style="text-align: left; font-weight: bold; font-size: 24px">Program</p>
    <p style="text-align: left;">The OCMA is proud to release the program to our <span style="text-decoration: underline; font-weight: bold;">100% online, 100% free</span> virtual symposium held October 27th - 29th, 2020. Have a look at our 14 sessions involving resources, strategies / ideas, and online learning and 3 end-of-day networking spaces to share your successes and challenges in teaching math remotely.  </p>
    <br/>
    <a target="_blank" href="https://mcusercontent.com/18496f202f4698b0b735d6c70/files/f9df167b-08fd-402e-a865-5efa96f1667b/VirtualSymposiumOctober2020.pdf" style="color: white; text-align: center; font-weight: bold; font-size: 24px; background-color: black; width: 40%; margin: 0 auto; padding: 1% 2% 1% 2%; border-radius: 40px; text-transform: uppercase;">Download Program</a>
    <br/>
    <br/>
    <div style="color: white; text-align: center; font-weight: bold; font-size: 24px; background-color: black; width: 40%; margin: 0 auto; padding: 1% 2% 1% 2%; border-radius: 40px; text-transform: uppercase;"><a style="color: white;" href=${virtualSymposiumLinkPdf} download>Links to Presentations</a></div>
    <br/>
    <br/>
   
    </div>
  `

  const virtualSymposiumArrayOfVids = [
    [video1, video2, video3, null, video5, null],
    [video6, video7, video8, null, video10, null],
    [video11, video12, video13, video14, null],
  ]

  const virtualSymposiumArrayOfPpts = [
    [null, ppt2, ppt3, ppt4, null],
    [null, ppt7, null, null, ppt10],
    [null, ppt12, ppt13, ppt14, null],
  ]

  return (
    <Layout>
      <SEO title={conferenceAcf.title} keywords={['Math', 'Conference']} />
      <ThemeProvider theme={styles.palette.blue}>
        {conferenceAcf.title === '40th Annual Conference' ? (
          <Hero imageURL={data.confHeroImage.childImageSharp.fluid}>
            <div className="container text-center">
              <Text h1>The OCMA Virtual Symposium 2020</Text>
            </div>
          </Hero>
        ) : (
          <Hero
            imageURL={
              conferenceAcf.banner_image.localFile.childImageSharp.fluid
            }
          >
            <div className="container text-center">
              <Text h1>{conferenceAcf.title}</Text>
            </div>
          </Hero>
        )}
      </ThemeProvider>
      <MainContentWrap>
        <CustomContainer>
          <ContentSection className="text-center">
            {conferenceAcf.title === '40th Annual Conference' ? null : (
              <Text h2>{conferenceAcf.thematic_title}</Text>
            )}
            {gallery && (
              <div>
                <StyledLink
                  css={css`
                    cursor: pointer;
                  `}
                  onClick={() => {
                    setYScroll({
                      y:
                        galleryRef.current.getBoundingClientRect().top +
                        window.pageYOffset,
                      reset: true,
                      from: { y: window.pageYOffset },
                      onFrame: (props) => window.scroll(0, props.y),
                    })
                  }}
                >
                  View Gallery
                </StyledLink>
              </div>
            )}
            <Text>{conferenceAcf.date}</Text>
            <div
              dangerouslySetInnerHTML={
                conferenceAcf.title === '40th Annual Conference'
                  ? { __html: virtualSymposiumContent }
                  : { __html: conferenceAcf.description }
              }
              css={css`
                margin-bottom: ${styles.margin.mid};
              `}
            />
            {conferenceAcf.feedback_link && (
              <StyledLink
                border
                css={css`
                  color: ${styles.colors.blueDark};
                `}
                to={conferenceAcf.feedback_link.url}
              >
                {conferenceAcf.feedback_link.title}
              </StyledLink>
            )}
            {conferenceAcf.contact_text && (
              <div
                dangerouslySetInnerHTML={{ __html: conferenceAcf.contact_text }}
              />
            )}
          </ContentSection>
          {
            // conferenceAcf.title === '40th Annual Conference' ?
            // null :
            <ContentSection>
              {conferenceAcf.conference_day.map((day, indexDay) => (
                <div
                  key={`conferenceDay-${indexDay}-${Math.random()}`}
                  css={css`
                    margin-bottom: ${styles.margin.mid};
                  `}
                >
                  <Text
                    h2
                    css={css`
                      margin-bottom: ${styles.margin.small};
                    `}
                  >
                    {day.day}
                  </Text>
                  {day.time_slot.map((slot, indexTime) => (
                    <div
                      key={`conferenceSlot-${indexTime}-${Math.random()}`}
                      css={css`
                        display: flex;
                        width: 100%;
                        margin-bottom: 6px;
                        ${styles.mq[1]} {
                          flex-direction: column;
                        }
                      `}
                    >
                      <div
                        css={css`
                          /* width: 15%; */
                          flex-basis: 15%;
                          background-color: ${styles.colors.blueDark};
                          padding: ${styles.margin.small};
                        `}
                      >
                        <div
                          css={css`
                            color: ${styles.colors.white};
                            text-align: center;
                            font-weight: bold;

                            & > * {
                              margin: 0;
                            }
                          `}
                          dangerouslySetInnerHTML={{
                            __html: slot.time_column_text,
                          }}
                        />
                      </div>
                      <div
                        css={css`
                          flex: 1;
                        `}
                      >
                        {slot.presentation.map((presentation, i) => (
                          <div
                            key={`conferencePresentation-${i}-${Math.random()}`}
                            css={css`
                              box-shadow: 2px 2px rgba(0, 0, 0, 0.05);
                              margin-bottom: 6px;
                              background-color: ${styles.colors.whiteAlt};

                              display: flex;
                              justify-content: space-between;
                              align-items: center;

                              &:last-child {
                                margin-bottom: 0;
                              }
                              ${styles.mq[1]} {
                                flex-direction: column;
                                align-items: flex-start;
                              }
                            `}
                          >
                            <div
                              css={css`
                                padding: ${styles.margin.small};
                              `}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: presentation.description,
                                }}
                              />
                              {virtualSymposiumArrayOfVids[indexDay][
                                indexTime
                              ] ? (
                                <div>
                                  <a
                                    href={
                                      virtualSymposiumArrayOfVids[indexDay][
                                        indexTime
                                      ]
                                    }
                                    download
                                  >
                                    Watch the Video!
                                  </a>
                                </div>
                              ) : null}
                              {virtualSymposiumArrayOfPpts[indexDay][
                                indexTime
                              ] ? (
                                <div>
                                  <a
                                    href={
                                      virtualSymposiumArrayOfPpts[indexDay][
                                        indexTime
                                      ]
                                    }
                                    download
                                  >
                                    Download the Presentation
                                  </a>
                                </div>
                              ) : null}
                              <Text
                                pSmall
                                className="m-0"
                                css={css`
                                  color: ${styles.colors.red};
                                  font-weight: bold;
                                `}
                              >
                                {presentation.location
                                  ? `Location: ${presentation.location}`
                                  : null}
                              </Text>
                              {/* {presentation.button &&
                                presentation.button.map((button) => (
                                  <div
                                    className="mt-4"
                                    key={`presentationButton ${Math.random()}`}
                                  >
                                    below not useful?
                                    {button.acf_fc_layout === 'link' && (
                                      <StyledLink
                                        block
                                        color={styles.colors.blueDark}
                                        to={button.link.url}
                                      >
                                        {button.link.title}
                                      </StyledLink>
                                    )}
                                    {button.acf_fc_layout === 'file' && (
                                      <StyledLink block to={button.file.link}>
                                        {button.button_text}
                                      </StyledLink>
                                    )}
                                  </div>
                                ))} */}
                            </div>
                            {presentation.logo_fields !== null && (
                              <div
                                css={css`
                                  flex-shrink: 0;
                                  padding: ${styles.margin.small};
                                `}
                              >
                                {presentation.logo_fields.logo_image &&
                                  presentation.logo_fields.logo_image
                                    .localFile && (
                                    <Link
                                      to={
                                        presentation.logo_fields.logo_link &&
                                        presentation.logo_fields.logo_link.url
                                      }
                                    >
                                      <img
                                        src={
                                          presentation.logo_fields.logo_image
                                            .localFile.childImageSharp.fluid.src
                                        }
                                        alt=""
                                      />
                                    </Link>
                                  )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </ContentSection>
          }

          {conferenceAcf.title === '40th Annual Conference'
            ? null
            : conferenceAcf.sponsors_heading && (
                <ContentSection>
                  <Text
                    h4
                    css={css`
                      margin-bottom: ${styles.margin.mid};
                    `}
                  >
                    {conferenceAcf.sponsors_heading}
                  </Text>
                  <div className="row">
                    {conferenceAcf.sponsors.map((sponsor, i) => (
                      <div
                        className="col-md-6"
                        key={`confSponsor_${i}`}
                        css={css`
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          flex-direction: column;
                          border-bottom: 2px solid ${styles.colors.whiteAlt};
                          margin-bottom: ${styles.margin.mid};
                        `}
                      >
                        {sponsor.logo && sponsor.logo.localFile && (
                          <Link
                            to={sponsor.link}
                            css={css`
                              margin-bottom: ${styles.margin.small};
                              text-align: center;
                            `}
                          >
                            <img
                              src={
                                sponsor.logo.localFile.childImageSharp.resize
                                  .src
                              }
                              alt=""
                              css={css`
                                max-width: 80%;
                              `}
                            />
                          </Link>
                        )}
                        {sponsor.thank_you_note && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: sponsor.thank_you_note,
                            }}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </ContentSection>
              )}

          {gallery && (
            <ContentSection>
              <AnchorTarget id="gallery" ref={galleryRef} />
              <Text h1 className="text-right">
                {data.galleryPage.title}
              </Text>
              <Gallery
                images={gallery}
                backdropClosesModal
                enableImageSelection={false}
              />
              <div style={{ clear: 'both' }} />
            </ContentSection>
          )}
          <ContentSection
            css={css`
              padding-top: ${styles.margin.large};
            `}
          >
            <Text h1 className="text-center">
              <Link to="/events">All Events</Link>
            </Text>
          </ContentSection>
        </CustomContainer>
      </MainContentWrap>
    </Layout>
  )
}
